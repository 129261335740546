html, body, main, #root, .App, main > div {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #f7f7f7;
  position: relative;

  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}

main.loggedin{
  box-sizing: border-box;
  padding: 56px 0 5px 0;
}

main.loggedin > div{
  height: auto;
  min-height: 100%;
}

.rel {
  position: relative;
}

.shadowless {
  box-shadow: none !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.hidden{
  display: none !important;
}

.drawer{
  position: fixed;
}

.tmla-mask{
  position: fixed;
}

.background-color{
  background: #f7f7f7;
}

.simple-card{
  margin: 10px;
}

.rel{
  position: relative;
}

.top-padding{
  padding-top: 10px;
}

.top-margin{
  margin-top: 20px;
}

.fullwidth{
  width: 100%;
}

.invoice-swipe .react-swipeable-view-container{
  height: calc(100vh - 128px) !important;
}

.invoice-swipe .react-swipeable-view-container > div{
  height: 100%;
}

.flex{
  flex:1;
}

.right-margin{
  margin-right: 16px;
}

.just-flex{
  display: flex;
}

.flex-2{
  display: flex;
  justify-content: space-between;
}

.flex-2 > * {
  width: 48%;
  box-sizing: border-box;
}

.flex-3 {
  display: flex;
  justify-content: space-between;
}

.flex-3 > * {
  width: 32%;
  box-sizing: border-box;
}

.app-bar{
  /*box-shadow: none !important;*/
  z-index: 1 !important;
}

.below-app-bar{
  top: 56px !important;
  z-index: 1 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14) !important;
}

div.preloader{
  display: block;
  margin: 20px auto;
}

.char-overview-avatar{
  background-color: transparent !important;
  color: rgb(185, 185, 185) !important;
  margin-left: -10px;
  margin-right: 25px;
}

button.default-fab{
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.animate-overlay-wrapper {
  position: fixed;
  z-index: 2;
  top:0;
  left:0;
  bottom:0;
  right:0;
}

@media (min-width: 0px) and (orientation: landscape){
  header.overlay-appbar div {
    min-height: 56px !important;
  }
}
