.overlay{
  z-index:2;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  animation-duration: 0.2s;
  overflow-y: auto;
}

.overlay main{
  min-height: calc(100%);
  box-sizing: border-box;
}

.overlay header.bigimage{
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-image: url(./bg.png);
  height: 240px;
  position: relative;
  overflow: hidden;
}

.overlay header.bigimage.small{
  height: 68px;
}

.overlay header.bigimage .color-overlay{
  background: #009688;
  transition: ease-in 0.2s;
  opacity: 0;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.overlay header.bigimage.blue .color-overlay{
  opacity: 1;
}

.overlay .app-bar-ov{
  z-index: 2;
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.overlay .white-btn{
  color: white;
  vertical-align: middle;
}

.overlay .white-btn:disabled{
  opacity: 0.2;
}

.overlay header.bigimage .title{
  position: absolute;
  color: white;
  bottom: 10px;
  width: 100%;
  text-align: left;
  font-size: 36px;
  box-sizing: border-box;
  padding: 0 25px;
}

.overlay header.bigimage .title.with-sub{
  bottom: 30px;
}

.overlay header.bigimage .subtitle{
  position: absolute;
  color: white;
  bottom: 15px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 25px;
}

.overlay-appbar{
   position: 'relative'
}

.overlay section.top-margin{
  margin-top: 76px;
}

.overlay section.top-margin-small{
  margin-top: 56px;
}

.overlay .real-app-bar-back-button{
  margin-left: -10px;
}
