.invoice-overlay .image {
  z-index: 2;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  animation-duration: 0.2s;
  overflow-y: auto;
}

.invoice-overlay .app-bar-iov{
  position: fixed;
  top:0;
  left:0;
  right:0;
  z-index: 3;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.invoice-overlay .app-bar-iov div{
  display: inline-block;
}
