.login-wrap {
  height: 100vh;
  width: 100vw;
  position: relative;
}
.login-wrap .ov {
  color: white;
  padding: 30px 0 0 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.login-wrap .ov img {
  width: 100px;
  margin: 20px 0;
}
.login-wrap .ov.overview .btns {
  position: absolute;
  bottom: 10%;
}
.login-wrap .ov.overview img {
  width: 128px;
  margin: 30% 0 0 0;
}
.login-wrap .ov.overview h1 {
  margin-top: 5px;
  font-weight: lighter;
  color: rgb(176, 194, 210);
}
.login-wrap .ov h1 {
  color: rgba(255, 255, 255, 0.85);
}
.login-wrap .ov h2 {
  color: rgba(255, 255, 255, 0.7);
}
.login-wrap .ov .box {
  position: absolute;
  left: 10%;
  width: 80%;
  transition: all .2s ease-in-out;
  bottom: 40px;
}
.login-wrap .bg {
  /* background: url(bg.jpg) no-repeat center center fixed; */
  background: rgb(95, 114, 131);
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.login-wrap a i {
  font-size: 18px;
  display: inline-block;
  margin: 0 10px 0 0;
}
.login-wrap .social-button{
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  padding: 15px;
  bottom: 20px;
  margin: 0 15px;
}
.login-wrap .social-button img{
  width: 30px;
  height: 30px;
  margin: 0;
}
.login-wrap .social-button.gplus{
  background: #4a5e6f !important;
}
.login-wrap .social-button.fb{
  background: #afbcc7 !important;
}
.login-wrap .input{
  width: 100%;
}
.login-wrap form button{
  display: block;
  margin: 20px auto;
}
.login-wrap .login-buttons{
  margin-top: 40px;
}
.login-wrap .login-buttons button{
  margin: 0 auto;
}
.login-wrap .social-buttons{
  margin: 0;
}

.login-error{
  font-size: 14px;
  padding: 5px 10px;
  display: inline-block;
  color: #ff1744;
}

.login-wrap .overview-button{
  width: 85vw;
  max-width: 100%;
  padding: 16px;
  border-radius: 32px;
  margin: 10px 0;
  font-size: 18px;
}
.login-wrap .overview-button.login-button{
  background: white;
  color: rgb(95, 114, 131);
}
.login-wrap .overview-button.register-button{
  border: 1px solid white;
  color: white;
}

.login-wrap .divider {
  margin: 10px 0 -20px 0;
	width: 100%;
	text-align:center;
  color: white;
  font-style: italic;
}

.login-wrap .divider hr {
	margin-left:auto;
	margin-right:auto;
	width: 40%;
  border: 0;
  height: 0;
  border-top: 1px solid white;
}

.login-wrap .left {
	float:left;
}

.login-wrap .right {
	float:right;
}

.login-wrap form.login {
  margin-top: 60px;
}

.login-wrap .backbutton {
	position: absolute;
  top: 10px;
  left: 10px;
}
