.blur{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background: rgba(255, 255, 255, 0.8);
  transition: ease-in .2s;
}

.blur.abs{
  position:absolute;
}
.blur.trans{
  background: transparent !important;
}
.blur.dark{
  background: rgba(0, 0, 0, 0.6);
}

.spinner{
  position: fixed;
  top: calc(40% - 30px);
  left: calc(50% - 30px);
  text-align: center;
}

.blur.abs .spinner{
  position:absolute;
}
