.ptr-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  border-radius: 50%;
  -webkit-animation: ptr-loading 0.4s infinite alternate;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  -webkit-animation-delay: 0;
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s !important;
}

@-webkit-keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

@keyframes ptr-loading {
  0% {
    -webkit-transform: translateY(0) scale(0.3);
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: #333;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
  -webkit-transform: translate3d(0, 30px, 0);
  transform: translate3d(0, 30px, 0);
}
