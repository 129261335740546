
.quota-card .vspace {
  height: 40px;
}

.quota-card .quota-box {
  margin: 20px 0;
}

.quota-card .quota-box h3 {
  margin: 5px 0;
  font-weight: normal;
  color: rgb(131, 131, 131);
  font-size: 16px;
}

.quota-card .quota-box h3 b {
  color: rgb(33, 150, 244);
}

.quota-card .vspace-small {
  height: 20px;
}

.quota-card .feature-item {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.quota-card .feature-item p {
  color: rgb(199, 199, 199);
}

.quota-card .feature-item span {
  margin: 10px;
  border-radius: 50%;
  background: rgb(199, 199, 199);
  color: white;
  height: 50px;
  width: 50px;
  display: block;
  text-align: center;
  padding-top: 12px;
  box-sizing: border-box;
}

.quota-card .feature-item.enabled p {
  color: rgb(33, 150, 244);
}

.quota-card .feature-item.enabled span {
  background: rgb(33, 150, 244);
}
