.list-textfield.street{
  width: 80%;
}
.list-textfield.streetnr{
  margin-left: 3%;
  width: 17%;
}

.list-textfield.plz{
  width: 30%;
}
.list-textfield.city{
  margin-left: 3%;
  width: 67%;
}
