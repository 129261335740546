.list-textfield{
  width: 100%;
}

.button-preloader-wrapper{
  position: relative;
  display: inline-block;
}

.button-preloader{
  color: white !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.button-preloader.gray{
  color: #9e9e9e !important;
}

.App.Desktop {
  overflow-x: hidden;
}

body::-webkit-scrollbar, .scrollbar::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb, .scrollbar::-webkit-scrollbar {
  background: #666;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
}

.scrollbar-hidden::-webkit-scrollbar {
  background: transparent;
}

.hidden {
  display: none;
}
