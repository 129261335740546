.user-info{
  height: 157px;
  padding: 30px 0 0 18px;
  box-sizing: border-box;
  position: relative;
}

.user-info .avatar{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user-info .text-area{
  position: absolute;
  bottom: 12px;
  left: 18px;
  right: 0;
}

.user-info .text-area .title{
  color: white;
}

.user-info .text-area .more-button{
  position: absolute;
  bottom: -12px;
  right: 0;
  color: white;
}
