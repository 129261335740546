.subscription-card .type {
  font-weight: lighter;
  width: 80%;
  margin: 20px auto;
  padding: 20px 0;
  border-radius: 4px;
  color: white;
  background: rgb(187, 187, 187);
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-style: italic;
}
