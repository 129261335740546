.intro-wrapper, .intro-wrapper > div{
  height: 100%;
}

.react-swipeable-view-container{
  position: relative;
  height: calc(100% - 54px);
}

.react-swipeable-view-container > div{
  height: 100vh;
}

.slide{
  height: 100vh;
  width: 100%;
}

div.stepper{
  position: absolute;
  bottom:0;
  left:0;
  right:0;
  height: 38px;
}

div.stepper > button{
  width: 100px;
}
