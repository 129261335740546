.setup-page {
  position: absolute;
  padding: 24px;
  top: 0;
  right: 0;
  left: 0;
}

/* Fucking dirty fix... */
.setup-page-no-x-scroll > div > div {
  overflow-x: hidden;
}
